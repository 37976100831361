import axios from 'axios';
import moment from 'moment';
export async function getOverride(bookingId) {
    const res = await axios.get(`GetBookingOverride`, {
        params: {
            taskId: bookingId,
        }
    });
    return res.data;
}
export async function deleteOverride(id, selectedDate, actionType) {
    const res = await axios.get(`DeleteBookingOverride`, {
        params: {
            id,
            selectedDate: moment(selectedDate).format('YYYY-MM-DD'),
            eventActionType: actionType,
        }
    });
    return res.data;
}
export async function editOverride(id, selectedDate, data, actionType) {
    const res = await axios.post(`EditBookingOverride`, data, {
        params: {
            id,
            selectedDate: moment(selectedDate).format('YYYY-MM-DD'),
            eventActionType: actionType,
        }
    });
    return res.data;
}
export async function addOverride(data) {
    const res = await axios.post('BookingOverride', data);
    return res.data;
}