<template>
  <NavBar />
  <Dialog
    :draggable="false"
    v-model:visible="isModalVisible"
    :modal="true"
    :header="modalHeader"
    :style="{ width: '50vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <div>
      <BookingOverrideChoiceComponent
        v-if="!isEditMode"
        :bookingType="bookingType"
        @booking-type-change="(theType) => (bookingType = theType)"
      />
      <Dropdown
        v-model="selectedcategory"
        :disabled="isEditMode"
        :options="categories"
        optionLabel="title"
        placeholder="Select a Category"
        class="w-full my-3"
      />
      <BookingFormComponent
        :key="selectedcategory.id"
        v-if="bookingType == 'booking'"
        :categoryId="selectedcategory.id"
        :selected-date="selectedEvent.startDate"
        :bookingId="selectedEvent.bookingId"
        @booking-form-close="handleBookingFormClose"
      />
      <OverrideFormComponent
        v-if="bookingType == 'override'"
        :categoryId="selectedcategory.id"
        :booking-id="selectedEvent.bookingId"
        :selected-date="selectedEvent.startDate"
        :end-date="selectedEvent.endDate"
        @override-form-close="handleBookingFormClose"
      />
    </div>
  </Dialog>
  <div class="custom-grid-tablet" style="height: 100vh">
    <div style="overflow-y: auto; height: 100%; padding-right: 5px">
      <Button
        class="w-full"
        label="Create"
        icon="pi pi-plus"
        outlined
        @click="isModalVisible = true"
      />
      <CalendarComponent
        class="w-full mx-auto my-1"
        id="calendar"
        :value="currentDate"
        :change="(e) => (currentDate = e.value)"
      />
      <CategoryCheckboxListComponent
        :categories="categories"
        v-if="categories.length"
        @category-check-changed="filterCalendarData"
      />
      <PatientCountComponent
        class="mb-1"
        :morning="patientBookingCount.morning"
        :afternoon="patientBookingCount.afternoon"
        :night="patientBookingCount.night"
      />
      <BreakFilterComponent @filter-change="filterCalendarData" />
    </div>
    <div style="height: 100vh">
      <ScheduleComponent
        v-model="currentDate"
        :selected-date="currentDate"
        height="100%"
        currentView="Agenda"
        :navigating="
          (args) => {
            currentDate = args.currentDate;
            fetchCalendarData(args.currentDate);
          }
        "
        :eventRendered="onEventRendered"
        enablePersistence="true"
        :popupOpen="onPopupOpen"
        :eventSettings="{ dataSource: currentlyFilteredRecords }"
      >
        <ViewsDirective>
          <!-- <ViewDirective option="Day" /> -->
          <!-- <ViewDirective option="Week" /> -->
          <ViewDirective option="Month" />
          <ViewDirective option="Agenda" />
        </ViewsDirective>
      </ScheduleComponent>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted, provide } from "vue";
import SignalRService from "@/helpers/signalRSetup";
import NavBar from "@/components/navbar.vue";
import { CalendarComponent } from "@syncfusion/ej2-vue-calendars";
import {
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Day,
  Week,
  Agenda,
  Month,
} from "@syncfusion/ej2-vue-schedule";
import BookingFormComponent from "@/components/BookingFormComponent.vue";
import {
  getBookingCategories,
  getBookingCalendar,
  getPatientBookingCount,
} from "@/services/booking.service";
import BreakFilterComponent from "@/components/BreakFilterComponent.vue";
import PatientCountComponent from "@/components/PatientCountComponent.vue";
import {
  getCurrentBreakFilter,
  getCurrentCheckedCategories,
} from "@/helpers/utils";
import BookingOverrideChoiceComponent from "@/components/BookingOverrideChoiceComponent.vue";
import OverrideFormComponent from "@/components/OverrideFormComponent.vue";
import CategoryCheckboxListComponent from "@/components/CategoryCheckboxListComponent.vue";
const bookingType = ref("");
const modalHeader = ref("New Booking / Override Slot");
const isModalVisible = ref(false);
const categories = ref([]);
const selectedcategory = ref(null);
let calendarDataSource = [];
const currentlyFilteredRecords = ref([]);
const currentDate = ref(
  JSON.parse(localStorage.getItem("schedule"))?.selectedDate || new Date()
);
const isEditMode = ref(false);
const selectedEvent = ref({
  bookingId: null,
  categoryId: null,
  startDate: null, //should be the selected date from the calendar cell,
  endDate: null,
});
const patientBookingCount = ref({
  morning: "0 (MORNING)",
  afternoon: "0 (AFTERNOON)",
  night: "0 (NIGHT)",
});

provide("schedule", [Day, Week, Month, Agenda]);
onMounted(() => {
  //fetch data
  fetchBookingCategories();
  fetchCalendarData(currentDate.value);

  //signal r client for callback (refresh data)
  SignalRService.subscribe("notifyBooking", () => {
    fetchCalendarData(currentDate.value);
  });
  SignalRService.subscribe("notifyBookingCategory", fetchBookingCategories);

  //initialize signal r connection
  SignalRService.initializeConnection()
    .then((connId) => {
      console.log("SignalR connection established", connId);
    })
    .catch((error) => {
      console.error("Failed to connect to SignalR", error);
    });
});
function fetchBookingCategories() {
  getBookingCategories().then((res) => {
    if (res.success && res.result) {
      categories.value = res.result;
      selectedcategory.value = res.result[0];
    }
  });
}
function fetchPatientBookingCount(categoryIds) {
  if (!categoryIds.length) return;
  getPatientBookingCount(categoryIds, currentDate.value).then((res) => {
    patientBookingCount.value = res.result;
  });
}
function filterCalendarData() {
  const savedBreakFilter = getCurrentBreakFilter();
  const checkedCategoryIds = getCurrentCheckedCategories();
  let filteredData = calendarDataSource;
  if (savedBreakFilter.value === 1) {
    filteredData = calendarDataSource.filter(
      (event) => event.OverrideType !== 2
    );
  } else if (savedBreakFilter.value === 2) {
    filteredData = calendarDataSource.filter(
      (event) => event.OverrideType !== 1
    );
  }
  const finalFilteredData = filteredData.filter(
    (event) =>
      checkedCategoryIds.includes(event.CategoryId) || event.CategoryId == null
  );
  currentlyFilteredRecords.value = finalFilteredData;
  fetchPatientBookingCount(checkedCategoryIds);
}
function handleBookingFormClose() {
  isModalVisible.value = false;
  fetchCalendarData(currentDate.value);
}
function fetchCalendarData(date) {
  getBookingCalendar(date, date).then((res) => {
    if (res.success) {
      calendarDataSource = res.result;
      filterCalendarData();
    }
  });
}
function onEventRendered(args) {
  args.element.style.backgroundColor = args.data.Color;
}
function onPopupOpen(args) {
  if (args.type === "EventContainer") {
    return;
  }
  args.cancel = true;
  if (!args.data.TaskID) {
    selectedEvent.value = {
      ...selectedEvent.value,
      startDate: args.data.StartTime,
      endDate: args.data.EndTime,
    };
    isModalVisible.value = true;
    return;
  }
  selectedEvent.value = {
    bookingId: args.data.TaskID,
    categoryId: args.data.CategoryId,
    startDate: args.data.StartTime,
    endDate: args.data.EndTime,
  };
  selectedcategory.value = categories.value.find(
    (category) => category.id === args.data.CategoryId
  );
  isEditMode.value = true;
  modalHeader.value =
    args.data.OverrideType === 0 ? "Edit Booking" : "Edit Override Slot";
  bookingType.value = args.data.OverrideType === 0 ? "booking" : "override";
  isModalVisible.value = true;
}
watch(bookingType, (newValue) => {
  if (!isEditMode.value) {
    if (newValue === "") {
      modalHeader.value = "New Booking / Override Slot";
      return;
    }
    modalHeader.value = newValue == "booking" ? "New Booking" : "Override Slot";
  }
});
watch(isModalVisible, (newValue) => {
  if (!newValue) {
    //reset all the fields once the main modal is closed
    modalHeader.value = "New Booking / Override Slot";
    bookingType.value = "";
    selectedEvent.value = {
      bookingId: null,
      categoryId: null,
      startDate: null,
    };
    isEditMode.value = false;
    if (categories.value.length > 0) {
      selectedcategory.value = categories.value[0];
    }
  }
});
</script>
