<template>
  <Dropdown
    v-model="selectedFilter"
    :options="filters"
    optionLabel="label"
    placeholder="Select Break"
    class="w-full"
    @change="onFilterChange"
  />
</template>
<script setup>
import { breakFilters, getCurrentBreakFilter } from "@/helpers/utils";
import { ref, defineEmits, onMounted } from "vue";
const emit = defineEmits(["filter-change"]);

const filters = ref(breakFilters);
const selectedFilter = ref(breakFilters[0]);

onMounted(() => {
  selectedFilter.value = getCurrentBreakFilter();
});

function onFilterChange() {
  localStorage.setItem("breakFilter", JSON.stringify(selectedFilter.value));
  emit("filter-change", selectedFilter.value.value);
}
</script>
