<template>
  <div class="flex flex-wrap align-items-center justify-content-center gap-3">
    <Button
      @click="emit('booking-type-change', 'booking')"
      label="New Booking"
      icon="pi pi-plus-circle"
      :severity="bookingType == 'booking' ? 'primary' : 'secondary'"
      size="large"
    />
    <Button
      @click="emit('booking-type-change', 'override')"
      label="Override Slot"
      icon="pi pi-clock"
      size="large"
      :severity="bookingType == 'override' ? 'primary' : 'secondary'"
    />
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
const emit = defineEmits(["booking-type-change"]);
defineProps(["bookingType"]);
</script>
