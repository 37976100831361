export const breakFilters = [
    { label: "Hide Break", value: 1 },
    { label: "Hide Override", value: 2 },
    { label: "Show Both", value: 3 },
];
export const weekDays = [
    { label: "Sunday", value: 1 },
    { label: "Monday", value: 2 },
    { label: "Tuesday", value: 3 },
    { label: "Wednesday", value: 4 },
    { label: "Thursday", value: 5 },
    { label: "Friday", value: 6 },
    { label: "Saturday", value: 7 },
];
export const repeatTypes = [
    { label: "Daily", value: 1 },
    { label: "Weekly", value: 2 },
    { label: "Monthly", value: 3 },
    { label: "Yearly", value: 4 }
];
export const overrideTypes = [
    { label: "Override", value: 1 },
    { label: "Break", value: 2 },
];
export const overrideActions = [
    { label: "This Event", value: 1 },
    { label: "This Event and Future Events", value: 2 },
    { label: "All Events", value: 3 },
];
export const dateRange = Array.from({ length: 31 }, (_, index) => ({ label: index + 1, value: index + 1 }));

export function getCurrentBreakFilter() {
    return JSON.parse(localStorage.getItem('breakFilter')) || breakFilters[0];
}
export function getCurrentCheckedCategories() {
    return JSON.parse(localStorage.getItem("checkedCategories")) || [];
}