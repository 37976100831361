<template>
  <div class="col-12">
    <form id="formSubmit" @submit.prevent="addOrUpdateBooking">
      <div class="grid">
        <div class="col-12">
          <label class="block mb-2 font-bold" for="FormField_DateTime"
            >Date & Time</label
          >
          <input
            ref="dateTimeInputRef"
            type="datetime-local"
            name="FormField_DateTime"
            id="FormField_DateTime"
            :value="bookingDate"
            class="p-inputtext p-component w-full"
            required
          />
        </div>
        <template v-for="(field, index) in bookingFields" :key="index">
          <div
            class="col-12"
            v-if="field.fieldType && field.fieldType != 'checkbox'"
          >
            <label
              class="block mb-2 font-bold"
              :for="'FormField_' + field.displayOrder"
              >{{ field.label }}</label
            >
            <input
              class="p-inputtext p-component w-full"
              :type="field.fieldType"
              :name="'FormField_' + field.displayOrder"
              :id="'FormField_' + field.displayOrder"
              :required="field.isRequired"
              :value="field.formValue"
            />
          </div>

          <div class="col-12" v-if="field.isMultipleChoice">
            <label
              class="block mb-2 font-bold"
              :for="'FormField_' + field.displayOrder"
              >{{ field.label }}</label
            >
            <select
              class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
              :name="'FormField_' + field.displayOrder"
              :id="'FormField_' + field.displayOrder"
              :value="field.formValue"
              :required="field.isRequired"
            >
              <option
                v-for="(text, index) in field.multipleQuestions"
                :key="index"
                :value="text"
              >
                {{ text }}
              </option>
            </select>
          </div>
          <div class="col-12" v-if="field.fieldType == 'checkbox'">
            <label
              class="block mb-2 font-bold"
              :for="'FormField_' + field.displayOrder"
              >{{ field.label }}</label
            >
            <input
              :id="'FormField_' + field.displayOrder"
              :type="field.fieldType"
              :checked="field.formValue === 'true'"
              @input="(e) => onCheckChange(e)"
            />
            <input
              :name="'FormField_' + field.displayOrder"
              :value="field.formValue === 'true' ? 'on' : 'off'"
              hidden
            />
          </div>
        </template>
        <div class="col-12 text-right buttons-grid">
          <Button size="small" label="Save" type="submit" icon="pi pi-check" />
          <Button
            v-show="props.bookingId"
            size="small"
            label="Resend Mail"
            icon="pi pi-envelope"
            @click="resend"
            severity="warning"
          />
          <Button
            v-show="props.bookingId"
            size="small"
            label="Reschedule"
            icon="pi pi-clock"
            @click="handleReschedule"
            severity="warning"
          />
          <Button
            v-show="props.bookingId"
            size="small"
            label="Delete"
            icon="pi pi-times"
            @click="cancelBookingEvent"
            severity="danger"
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script setup>
import moment from "moment";
import { onMounted, defineProps, ref, defineEmits } from "vue";
import { useToast } from "primevue/usetoast";
import {
  getBookingFields,
  getBookingFieldsWithData,
  addBooking,
  updateBooking,
  cancelBooking,
  resendEmail,
  reschedule,
} from "@/services/booking.service";
const props = defineProps({
  categoryId: Number,
  bookingId: {
    required: false,
    type: Number,
  },
  selectedDate: {
    required: false,
    type: Date,
  },
});
const emit = defineEmits(["booking-form-close"]);
const toast = useToast();
const bookingFields = ref([]);
const bookingDate = ref(null);
const dateTimeInputRef = ref(null);
let isBusy = false;
onMounted(() => {
  if (props.selectedDate) {
    bookingDate.value = moment(props.selectedDate).format("YYYY-MM-DDTHH:mm");
  } else {
    bookingDate.value = moment().format("YYYY-MM-DDTHH:mm");
  }
  if (props.bookingId) {
    getBookingFieldsWithData(props.categoryId, props.bookingId).then((res) => {
      bookingFields.value = res.result.bookings;
      bookingDate.value = res.result.bookingDate;
    });
  } else {
    getBookingFields(props.categoryId).then((res) => {
      bookingFields.value = res.result;
    });
  }
});
async function cancelBookingEvent() {
  if (isBusy) return;
  try {
    if (!confirm("Are you sure you want to delete this booking?")) {
      return;
    }
    isBusy = true;
    const res = await cancelBooking(props.bookingId, "no reason");
    toast.add({
      severity: res.success ? "success" : "error",
      detail: res.message,
      group: "br",
      life: 10000,
    });
    if (res.success) {
      //emit for close modal
      emit("booking-form-close");
    }
  } finally {
    isBusy = false;
  }
}

async function addOrUpdateBooking(e) {
  const formData = new FormData(e.target);
  if (isBusy) return;
  try {
    let res = null;
    isBusy = true;
    if (props.bookingId) {
      res = await updateBooking(props.bookingId, props.categoryId, formData);
    } else {
      res = await addBooking(props.categoryId, formData);
    }
    if (res) {
      toast.add({
        severity: res.success ? "success" : "error",
        detail: res.message,
        group: "br",
        life: 10000,
      });
      if (res.success) {
        //emit for close modal
        emit("booking-form-close");
      }
    }
  } finally {
    isBusy = false;
  }
}
async function resend() {
  if (isBusy) return;
  try {
    if (!confirm("Are you sure you want to resend email to this booking?")) {
      return;
    }
    isBusy = true;
    const res = await resendEmail(props.bookingId);
    toast.add({
      severity: res.success ? "success" : "error",
      detail: res.message,
      group: "br",
      life: 10000,
    });
    if (res.success) {
      //emit for close modal
      emit("booking-form-close");
    }
  } finally {
    isBusy = false;
  }
}
async function handleReschedule() {
  const dateTime = dateTimeInputRef.value?.value;
  if (!confirm("Are you sure you want to reschedule this booking?")) {
    return;
  }
  if (isBusy) return;
  try {
    isBusy = true;
    var res = await reschedule(props.bookingId, dateTime);
    toast.add({
      severity: res.success ? "success" : "error",
      detail: res.message,
      group: "br",
      life: 10000,
    });
    if (res.success) {
      //emit for close modal
      emit("booking-form-close");
    }
  } finally {
    isBusy = false;
  }
}
function onCheckChange(e) {
  if (e.target.checked) {
    e.target.nextSibling.value = "on";
  } else {
    e.target.nextSibling.value = "off";
  }
  console.log(e.target, e.target.nextSibling.value);
}
</script>
