<template>
  <form @submit.prevent="addOrUpdateOverride">
    <div class="grid">
      <!-- break or override radio btns -->
      <div v-for="option in overrideTypes" :key="option.value" class="col-12">
        <div>
          <input
            type="radio"
            v-model="overrideType"
            :value="option.value"
            :label="option.label"
            :id="option.label"
          />
          <label :for="option.label">{{ option.label }}</label>
        </div>
      </div>
      <!-- start date -->
      <div class="col-12">
        <label for="startDate" class="block">Start Date</label>
        <Calendar
          type="date"
          class="w-full"
          id="startDate"
          v-model="startDate"
          required
        />
      </div>
      <!-- time from - time to -->
      <div class="col-6">
        <label for="timeFrom" class="block">Time From</label>
        <input
          type="time"
          class="form-control p-component p-inputtext w-full"
          id="timeFrom"
          v-model="timeFrom"
          required
        />
      </div>
      <!-- time to -->
      <div class="col-6">
        <label for="timeTo" class="block">Time To</label>
        <input
          type="time"
          class="form-control p-component p-inputtext w-full"
          id="timeTo"
          v-model="timeTo"
          required
        />
      </div>
      <!-- slot capacity -->
      <div class="col-12" v-if="overrideType === 1">
        <label for="slotCapacity" class="block">Slot Capacity</label>
        <InputNumber min="0" v-model="slotCapacity" required class="w-full" />
      </div>
      <!-- is recurring -->
      <div class="col-12">
        <input
          type="checkbox"
          class="form-control"
          id="isRecurring"
          v-model="isRecurring"
        />
        <label for="isRecurring">Recurring</label>
      </div>
      <!-- recurring logic -->
      <div
        class="end-date-and-repeat-type-for-recurring grid col-12"
        v-if="isRecurring"
      >
        <!-- end date -->
        <div class="col-6">
          <label for="endDate" class="block">End Date</label>
          <Calendar
            type="date"
            class="w-full"
            id="endDate"
            v-model="endDate"
            :disabled="wasRecurring && isRecurring"
            required
          />
        </div>
        <!-- repeat type -->
        <div class="col-6">
          <label for="repeatType" class="block">Repeat Type</label>
          <select
            class="form-control p-component p-inputtext w-full"
            id="repeatType"
            v-model="repeatType"
            required
            @change="onRepeatTypeChange"
          >
            <option
              v-for="(type, index) in repeatTypes"
              :key="index"
              :value="type.value"
            >
              {{ type.label }}
            </option>
          </select>
        </div>
        <!-- repeat day of month -->
        <div class="col-6" v-if="showRepeatDayOfMonth">
          <label for="repeatOn" class="block">Repeat On</label>
          <MultiSelect
            v-model="repeatOn"
            :options="dateRange"
            optionLabel="label"
            optionValue="value"
            class="w-full"
          />
        </div>
        <!-- repeat on -->
        <div class="col-6" v-if="showRepeatWeek">
          <label for="repeatOn" class="block">Repeat On</label>
          <MultiSelect
            v-model="repeatOn"
            :options="weekDays"
            optionLabel="label"
            optionValue="value"
            class="w-full"
          />
        </div>
        <!-- repeat frequency -->
        <div class="col-6">
          <label for="repeatFrequency" class="block">Repeat Frequency</label>
          <InputNumber
            class="w-full"
            id="repeatFrequency"
            required
            min="1"
            v-model="repeatFrequency"
          />
        </div>
      </div>
      <div class="col-12 text-right buttons-grid">
        <Button
          label="Save"
          type="submit"
          icon="pi pi-check"
          v-show="!isEditMode"
        />
        <Button
          label="Edit"
          icon="pi pi-check"
          v-show="isEditMode"
          @click="
            () => {
              if (!isFormValid()) return;
              actionHeader = 'Edit Override';
              isActionModalVisible = true;
            }
          "
        />
        <Button
          label="Delete"
          icon="pi pi-check"
          severity="danger"
          class="ml-3"
          @click="
            () => {
              actionHeader = 'Delete Override';
              isActionModalVisible = true;
            }
          "
          v-show="isEditMode"
        />
      </div>
    </div>
  </form>
  <Dialog
    :draggable="false"
    :modal="true"
    v-model:visible="isActionModalVisible"
    :header="actionHeader"
    :style="{ width: '50vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <OverrideActionOptionComponent
      :isRecurring="wasRecurring"
      @action-edit="addOrUpdateOverride"
      @action-delete="removeOverride"
      :recurrenceRule="recurrenceRule"
      :actionMode="actionHeader"
    />
  </Dialog>
</template>

<script setup>
import moment from "moment";
import {
  weekDays,
  dateRange,
  repeatTypes,
  overrideTypes,
} from "@/helpers/utils";
import {
  getOverride,
  addOverride,
  editOverride,
  deleteOverride,
} from "@/services/override.service";
import InputNumber from "primevue/inputnumber";
import { ref, defineProps, defineEmits, onMounted, computed } from "vue";
import OverrideActionOptionComponent from "./OverrideActionOptionComponent.vue";
import { useToast } from "primevue/usetoast";
const toast = useToast();
const overrideType = ref(1); //override
const startDate = ref(new Date());
const endDate = ref(new Date());
const timeTo = ref("");
const timeFrom = ref("");
const slotCapacity = ref(0);
const isRecurring = ref(false);
const repeatOn = ref([]);
const wasRecurring = ref(false);
const repeatType = ref(0);
const showRepeatWeek = ref(false);
const showRepeatDayOfMonth = ref(false);
const repeatFrequency = ref(1);
const isEditMode = ref(false);
const isActionModalVisible = ref(false);
const actionHeader = ref("");
let prevOverride = null;
let isBusy = false;
const emit = defineEmits(["override-form-close"]);
const props = defineProps({
  selectedDate: Date,
  endDate: Date,
  bookingId: {
    type: Number,
    required: false,
  },
  categoryId: {
    type: Number,
    required: false,
  },
});
onMounted(() => {
  if (props.selectedDate) {
    startDate.value = props.selectedDate;
  }
  if (props.endDate) {
    endDate.value = props.endDate;
  }
  if (props.bookingId) {
    isEditMode.value = true;
  }
  if (props.bookingId) {
    getOverride(props.bookingId).then((res) => {
      const override = res.result;
      prevOverride = res.result;
      overrideType.value = override.overrideType;
      startDate.value = new Date(override.startDate);
      endDate.value = new Date(override.endDate);
      timeFrom.value = override.timeFrom;
      timeTo.value = override.timeTo;
      slotCapacity.value = override.slotCapacity;
      isRecurring.value = override.isRecurring;
      wasRecurring.value = override.isRecurring;
      repeatType.value = override.repeatType;
      showRepeatDayOfMonth.value = override.repeatType === 3;
      showRepeatWeek.value = override.repeatType === 2;
      repeatFrequency.value = override.repeatFrequency;
      repeatOn.value =
        override.repeatOn?.split(",").map((day) => parseInt(day)) || [];
    });
  }
});
const recurrenceRule = computed(() => {
  if (!prevOverride) return false;
  return (
    isRecurring.value != prevOverride.isRecurring ||
    startDate.value != prevOverride.startDate ||
    endDate.value != prevOverride.endDate ||
    repeatType.value != prevOverride.repeatType ||
    repeatFrequency.value != prevOverride.repeatFrequency ||
    repeatOn.value.map((x) => parseInt(x.value)).join(",") !=
      prevOverride.repeatOn
  );
});
async function addOrUpdateOverride(actionType) {
  if (isBusy) return;
  try {
    isBusy = true;
    if (!isFormValid()) {
      return;
    }
    let res = null;
    const override = {
      categoryId: props.categoryId,
      overrideType: overrideType.value,
      startDate: moment(startDate.value).format("YYYY-MM-DD"),
      endDate: moment(endDate.value).format("YYYY-MM-DD"),
      timeFrom: timeFrom.value,
      timeTo: timeTo.value,
      slotCapacity: overrideType.value === 2 ? null : slotCapacity.value, //if break, slot capacity is null
      isRecurring: isRecurring.value,
      repeatOn:
        repeatType.value === 1 || repeatType.value === 4
          ? null
          : repeatOn.value.map((day) => day).join(","), //day of week or day of month (multiple values)
      repeatType: repeatType.value,
      repeatFrequency: repeatFrequency.value,
    };
    if (props.bookingId) {
      //update override
      res = await editOverride(
        props.bookingId,
        props.selectedDate,
        override,
        actionType
      );
    } else {
      //create override
      res = await addOverride(override);
    }
    toast.add({
      severity: res.success ? "success" : "error",
      detail: res.message,
      group: "br",
      life: 10000,
    });
    if (res.success) {
      //also close the action popup is visible after save
      if (isActionModalVisible.value) {
        isActionModalVisible.value = false;
      }
      emit("override-form-close");
    }
  } finally {
    isBusy = false;
  }
}
async function removeOverride(actionType) {
  if (isBusy) return;
  try {
    isBusy = true;
    const res = await deleteOverride(
      props.bookingId,
      props.selectedDate,
      actionType
    );
    toast.add({
      severity: res.success ? "success" : "error",
      detail: res.message,
      group: "br",
      life: 10000,
    });
    if (res.success) {
      isActionModalVisible.value = false;
      emit("override-form-close");
    }
  } finally {
    isBusy = false;
  }
}
function onRepeatTypeChange() {
  //week
  showRepeatWeek.value = repeatType.value === 2;
  //month
  showRepeatDayOfMonth.value = repeatType.value === 3;
}
function isFormValid() {
  if (!props.categoryId) {
    toast.add({
      severity: "error",
      detail: "Please select a category",
      group: "br",
      life: 10000,
    });
    return;
  }
  if (overrideType.value === 1 && slotCapacity.value == null) {
    toast.add({
      severity: "error",
      detail: "Please enter valid slot capacity",
      group: "br",
      life: 10000,
    });
    return false;
  }
  if (isRecurring.value) {
    if (repeatType.value == null) {
      toast.add({
        severity: "error",
        detail: "Please select repeat type",
        group: "br",
        life: 10000,
      });
      return false;
    }
    if (
      (repeatType.value === 2 || repeatType.value === 3) &&
      !repeatOn.value.length
    ) {
      toast.add({
        severity: "error",
        detail: "Please select repeat on",
        group: "br",
        life: 10000,
      });
      return false;
    }
    if (repeatFrequency.value == null) {
      toast.add({
        severity: "error",
        detail: "Please enter valid repeat frequency",
        group: "br",
        life: 10000,
      });
      return false;
    }
  }
  return true;
}
</script>
