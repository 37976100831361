<template>
  <div
    v-for="category of categories"
    :key="category.id"
    class="flex align-items-center mb-1"
  >
    <Checkbox
      v-model="selectedCategories"
      :inputId="category.code"
      :data-category="category.id"
      :value="category.id"
      @change="checkChanged"
    />
    <label class="ml-2 text-sm" :for="category.code">{{
      category.title
    }}</label>
  </div>
</template>
<script setup>
import { getCurrentCheckedCategories } from "@/helpers/utils";
import { ref, defineProps, defineEmits, onMounted } from "vue";
const emit = defineEmits(["category-check-changed"]);
const props = defineProps(["categories"]);
const selectedCategories = ref(getCurrentCheckedCategories());
function checkChanged() {
  localStorage.setItem(
    "checkedCategories",
    JSON.stringify(selectedCategories.value)
  );
  emit(
    "category-check-changed",
    selectedCategories.value.map((x) => x)
  );
}
onMounted(() => {
  if (props.categories) {
    props.categories.forEach((c) => {
      const categoryChkbx = document.querySelector(`[data-category="${c.id}"]`);
      if (categoryChkbx && categoryChkbx.children[1]) {
        categoryChkbx.children[1].style.backgroundColor = c.color;
      }
    });
  }
});
</script>
