import { hubConnection } from "signalr-no-jquery";
class SignalRService {
    constructor(baseAddress) {
        this.connection = hubConnection(baseAddress);
        this.hubProxy = this.connection.createHubProxy("BookingHub");
        this.connectionStarted = false;
    }

    // Initialize the SignalR connection
    initializeConnection() {
        return new Promise((resolve, reject) => {
            if (this.connectionStarted) {
                resolve("Already connected");
                return;
            }

            this.connection
                .start({ jsonp: true })
                .done(() => {
                    this.connectionStarted = true;
                    resolve(this.connection.id);
                })
                .fail((error) => {
                    console.error("Connection failed", error);
                    reject(error);
                });
        });
    }

    // Subscribe to events with callbacks
    subscribe(eventName, callback) {
        if (!this.hubProxy) {
            console.error("Hub proxy not initialized");
            return;
        }
        this.hubProxy.on(eventName, callback);
    }

    // Unsubscribe from events
    unsubscribe(eventName) {
        if (!this.hubProxy) {
            console.error("Hub proxy not initialized");
            return;
        }
        this.hubProxy.off(eventName);
    }
}

export default new SignalRService(process.env.VUE_APP_BASE_ADDRESS);