<template>
  <!-- show radio buttons here for options -->
  <div v-for="option in actionOptions" :key="option.value">
    <div class="my-2">
      <input
        type="radio"
        v-model="selectedOption"
        :value="option.value"
        :label="option.label"
        :id="option.label"
      />
      <label :for="option.label">{{ option.label }}</label>
    </div>
  </div>
  <!-- show submit button here -->
  <Button
    label="Submit"
    icon="pi pi-check"
    class="mt-2"
    @click="handleSubmit"
  />
</template>
<script setup>
import { overrideActions } from "@/helpers/utils";
import { ref, defineEmits, defineProps, onMounted } from "vue";
const actionOptions = ref(overrideActions);
const selectedOption = ref(null);
const props = defineProps({
  recurrenceRule: Boolean,
  actionMode: String,
  isRecurring: Boolean,
});
const emit = defineEmits(["action-delete", "action-edit"]);
onMounted(() => {
  if (props.isRecurring && props.recurrenceRule) {
    actionOptions.value = overrideActions.filter(
      (option) => option.value !== 1
    );
  } else {
    actionOptions.value = overrideActions.filter(
      (option) => option.value === 1
    );
  }
});
function handleSubmit() {
  if (!selectedOption.value) {
    return;
  }
  if (props.actionMode.toLowerCase().includes("delete")) {
    emit("action-delete", selectedOption.value);
  } else {
    emit("action-edit", selectedOption.value);
  }
}
</script>
