import axios from "axios";
import moment from "moment";
export async function addBooking(categoryId, formData)
{
    const response = await axios.post("AddBooking", formData, {
        params: {
            CategoryId: categoryId,
        },
    });
    return response.data;
}
export async function updateBooking(bookingId, categoryId, formData)
{
    const response = await axios.post("EditBooking", formData, {
        params: {
            Id: bookingId,
            CategoryId: categoryId,
        },
    });
    return response.data;
}

export async function getBookingFields(categoryId)
{
    const response = await axios.get("GetCategoryFields", {
        params: {
            CategoryId: categoryId,
        },
    });
    return response.data;
}

export async function getBookingFieldsWithData(categoryId, bookingId)
{
    const response = await axios.get("GetCategoryFieldsValue", {
        params: {
            categoryId,
            bookingId,
        },
    });
    return response.data;
}

export async function getBookingCategories(isActive = true)
{
    const response = await axios.get("GetBookingCategory", {
        params: {
            isActive: isActive,
        },
    });
    return response.data;
}

export async function getBookingCalendar(startDate, endDate)
{
    const start = moment(startDate)
        .subtract(1, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
    const end = moment(endDate)
        .endOf("month")
        .add(1, "days")
        .format("MM/DD/YYYY");
    const response = await axios.get("GetBookingCalendar", {
        params: {
            start,
            end,
        },
    });
    return response.data;
}
export async function cancelBooking(bookingId, cancelReason)
{
    const response = await axios.post("CancelBooking", {
        bookingId,
        cancelReason,
    });
    return response.data;
}
export async function getPatientBookingCount(categoryIds, selectedDate)
{
    const response = await axios.get("GetPatientBookingCount", {
        params: {
            categoryIds: categoryIds,
            date: moment(selectedDate).format("MM/DD/YYYY"),
        },
        paramsSerializer: {
            indexes: true,
        },
    });
    return response.data;
}
export async function resendEmail(bookingId)
{
    const res = await axios.post(`ResendEmailBooking`, null, {
        params: {
            bookingId,
        },
    });
    return res.data;
}
export async function reschedule(bookingId, dateTime)
{
    const res = await axios.post(`RescheduleBooking`, {
        bookingId: bookingId,
        newDate: moment(dateTime).format("YYYY-MM-DD HH:mm:ss"),
    });
    return res.data;
}